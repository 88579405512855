// reset
html, body, h1, h2, h3, h4, ul, ol, dl, li, dt, dd, p, div, span, img, a, table, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-size: 100%;
  vertical-align:baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
article, header, footer, aside, figure, figcaption, nav, section { 
  display:block;
}
body {
  line-height: 1;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
ol, ul {
  list-style: none;
  list-style-type: none;
}
a { outline: 0;}

////////////////////////////////
@mixin sp($bp: 768px) {
  @media (max-width: $bp) {
    @content;
  }
}
@mixin pc($bp: 769px) {
  @media (min-width: $bp) {
    @content;
  }
}

.portrait {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 100%;
	height: auto;
}

.landscape {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}
.video {
	.landscape, .portrait {
		width: 100%;
		height: 100%;
	}
}

@include pc {
	.u-pc {display: block!important;}
	.u-sp {display: none!important;}
}

@include sp {
	.u-sp {display: block!important;}
	.u-pc {display: none!important;}
}

////////////////////////////////////


// content
body {
	font-family: "FOT-筑紫ゴシック Pr5N R","游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN","Hiragino Kaku Gothic Pro",メイリオ,Meiryo,sans-serif;
	font-size: 12px;
	background-color: #A8A8A8;
	color: #FFF;
	::selection {
		background: #000;
	}
	@include sp{
		// background-image: url('../images/bg_sp.svg');
		// background-position: 60% -250px;
		// background-size: 230%;
		font-size: 11px;
	}
	&:before {
		position:fixed;
		top:0;
		left:0;
		z-index:-1;
		width:100vw;
		height:100vh;
		background-image: url('../images/bg_pc.svg');
		background-repeat: no-repeat;
		background-position: center -430px;
		background-size: 110%;
		content:"";
		opacity: 0;
		@include sp{
			background-image: url('../images/bg_sp.svg');
			background-position: 60% -250px;
			background-size: 230%;
		}
	}
	&.opening:before {
		opacity: 1;
		transition: 1.5s;
	}
	&.open {
		overflow: hidden;
	}
}

.wrapper {
	max-width: 1390px;
	margin: 0 auto;
	width: 84%;
	opacity: 0;
	@include sp{
		width: 100%;
	}
	&.opening {
		opacity: 1;
		transition: 1.5s;
	}
}

.rex_logo {
	width: 48px;
	height: auto;
	position: fixed;
	right: 0;
	top: 0;
	transform: translateX(50px);
	transition: 1s;
	&.opening {
		transform: translateX(0);
	}
	@include sp{
		width: 25px;
		transform: translateX(30px);
	}
}

.top_container {
	// margin: 0 0 0 60px;
	padding-top: 60px;
	height: calc(100vh - 80px);
	 @include sp{
	 	height: 100%;
	 	margin: 0 0 0 20px;
	 	padding-top: 20px;
	 }
	img {
		display: block;
	}
	.logo {
		width: 217px;
		height: auto;
		margin-bottom: 30px;
		@include sp{
			width: 30%;
		}
	}
	.main_ttl {
		width: 612px;
		height: auto;
		margin-bottom: 30px;
		@include sp{
			width: 50%;
			margin-bottom: 20px;
		}
	}
	.sub_ttl {
		width: 460px;
		height: auto;
		margin-bottom: 75px;
		@include sp{
			width: 40%;
			margin-bottom: 35px;
		}
	}
	p {
		line-height: 2;
		letter-spacing: 0.12em;
		@include sp{
			margin-bottom: 40px;
			padding-right: 30px;
		}
	}
}

.main_container {
	@include sp{
		margin-bottom: 60px;
	}
	ul {
		// margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		margin-left: -10px;
		// max-width: 1600px;
		@include sp{
			margin: 0 20px 0 20px;
			// width: 100%;
		}
		li {
			width: 258px;
			height: 300px;
			margin: 10px 10px 35px 10px;
			@include sp{
				width: 46%;
				margin: 0 0 -70px 0;
			}
			a {
				color: #FFF;
				text-decoration: none;
				.date {
					margin: 7px 0 3px 0;
				}
				.comment {
					line-height: 1.5;
				}
				padding-bottom: 20px;
			.img_wrap {
				width: 258px;
				height: 258px;
				position: relative;
				overflow: hidden;
				@include sp{
					width: 100%;
					height: 53%;
				}
					img {
						transition: 0.3s;
					}
					video {
						pointer-events: none;
					}
			 }
			}
			@include pc{
				&:hover img {
					opacity: 0.5;
					transition: 0.3s;
				}
			}
		}
		@include sp{
			li:nth-child(odd) {
				margin-right: 5%;

			}
		}
	}
}


//modal
#modal {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0,0.8);
	display: none;
	z-index: 10;
	overflow: scroll;
	// transition: 0.5s;
	&.open {
	// display: block;
	// opacity: 1;
	// transition: 0.5s;
}
	.modal_inner {
		// max-width: 700px;
		// max-height: 800px;
		// margin: auto;
		// position: absolute;
		// top: 0;
		// bottom: 0;
		// left: 0;
		// right: 0;
		position: relative;
    width: 100%;
    height: 100%;
		@include sp{
			// width: 80%;
			// max-height: 500px;
		}
	.pic {
		text-align: center;
		img, video {
			// width: 100%;
			height: auto;
			max-height: 700px;
			max-width: 700px;
			@include sp{
				max-width: 100%;
			}
		}
	}
	.date {
		margin: 7px 0 3px 0;
	}
	.comment {
		line-height: 1.5;
		@include sp{
			padding-bottom: 20px;
			}
		}
	.close_btn {
		width: 21px;
		height: auto;
		right: -40px;
		position: absolute;
		cursor: pointer;
		@include sp{
			width: 87px;
			right: 0;
			top: -30px;
			}

		}
	}
	.modal_posi {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		@include sp{
			width: 75%;
			max-height: 500px;
			// top: calc(50% + 70px);
		}
	}
}

footer {
	position: relative;
	bottom: 0;
	margin: 100px 0 20px 0;
	@include sp{
		margin: 0 20px 20px 20px;
	}
	a {
		width: 360px;
		display: block;
		// overflow: hidden;
		@include sp{
			width: 100%;
		}
		img {
			display: inline-block;
		}
		.top_back {
			margin-left: -1px;
			width: 95%;
		}
		.arrow {
			position: relative;
			top: -5px;
			left: 15px;
			width: 24px;
			height: auto;
			@media only screen and (min-device-width:320px) and (max-device-width:374px) {
				width: 17px;
			}
		}
	}
	.copyright {
		font-size: 10px;
		position: absolute;
		right: 0;
		bottom: 0;
		letter-spacing: 0.25em;
		@include sp{
			position: static;
			margin-top: 15px;
		}
	}
}